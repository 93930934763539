exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-business-index-jsx": () => import("./../../../src/pages/business/index.jsx" /* webpackChunkName: "component---src-pages-business-index-jsx" */),
  "component---src-pages-carclean-index-jsx": () => import("./../../../src/pages/carclean/index.jsx" /* webpackChunkName: "component---src-pages-carclean-index-jsx" */),
  "component---src-pages-company-index-jsx": () => import("./../../../src/pages/company/index.jsx" /* webpackChunkName: "component---src-pages-company-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-kitchencar-index-jsx": () => import("./../../../src/pages/kitchencar/index.jsx" /* webpackChunkName: "component---src-pages-kitchencar-index-jsx" */),
  "component---src-pages-teluruion-full-index-jsx": () => import("./../../../src/pages/teluruion_full/index.jsx" /* webpackChunkName: "component---src-pages-teluruion-full-index-jsx" */),
  "component---src-pages-teluruion-part-index-jsx": () => import("./../../../src/pages/teluruion_part/index.jsx" /* webpackChunkName: "component---src-pages-teluruion-part-index-jsx" */),
  "component---src-pages-telurunishi-full-index-jsx": () => import("./../../../src/pages/telurunishi_full/index.jsx" /* webpackChunkName: "component---src-pages-telurunishi-full-index-jsx" */),
  "component---src-pages-telurunishi-part-index-jsx": () => import("./../../../src/pages/telurunishi_part/index.jsx" /* webpackChunkName: "component---src-pages-telurunishi-part-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

